.chatBox {
    flex-grow: 1;
    overflow: auto;
}


.chatBoxComponent {
    padding-left: 1rem;
    padding-right: 1rem;
    color: #3b444b;
}

.chatBoxComponentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
    padding-bottom: 3.5rem;
}

.chatBoxMsg {
    display: flex;
    align-items: flex-start;
    gap: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    width: 80%;
}

.chatBoxMsg p {
    margin: 2px 5px
}

.chatBoxMsgActiveUser {
    justify-content: flex-end;
    align-self: flex-end;
}

.chatBoxMsgOtherUser {
    justify-content: flex-start;
    align-self: flex-start;
}

.chatBoxMsgContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1.25rem;
}

.chatBoxMsgLeftImgContainer {
    min-width: max-content;
}

.chatBoxMsgLeftImgContainer .leftImg {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    object-fit: cover;
}

.chatBoxMsgBody {
    padding-left: 0.25rem;
    padding-right: 0.25rem;

}

.chatBoxMsgBodyMedia {
    width: 100%;
    height: 220px;
    object-fit: contain;
}

.chatBoxMsgActiveUserMsgBody {
    background-color: #e2f3fb;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    min-height: 40px;

}

.chatBoxMsgOtherUserMsgBody {
    background-color: #e5e7eb;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    min-height: 40px;
}

.chatBoxMsgTimeAndSign {
    font-size: 10px;
    color: #64748b;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: max-content;
}

.chatBoxMsgRightImgContainer {
    min-width: max-content;
    display: flex;
    justify-content: flex-end;
}

.chatBoxMsgRightImgContainer .rightImg {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    object-fit: cover;
}

.moreFunc {
    cursor: pointer;
    opacity: 0;
}

.showEmoji {
    position: absolute;
    right: 20px;
    background-color: white;
    border-radius: 100%;
    z-index: 50;
}


.chatBoxMsg{
    position: relative;
}

.leftEmojiPicker {
    position: absolute;
    right: calc(100% - 550px);
    top: 0%;
    z-index: 100;
}
.rightEmojiPicker {
    position: absolute;
    left: calc(100% - 550px);
    top: 0%;
    z-index: 100;
}

.chatBoxMsg:hover .moreFunc {
    opacity: 100;
    align-items: center;
    gap: 10px
}

.read{
    color:#38ACEC;
    font-weight: bold;
}

.event-message {
    margin: 10px;
    padding: 5px;
    background-color: #e5ebf3;
    border-radius: 10px;
    /* width: ; */
}

@media (max-width: 900px) {
    .rightEmojiPicker {
        left: calc(100% - 350px);
        
    }
}
@media (max-width: 768px) {
    .chatBoxMsg {
        width: 100%;
    }
}