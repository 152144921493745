.filterListContainer {
    position: relative;
}

.filterIcon {
    cursor: pointer;
    color: #38ACEC;
}

.filterBody {
    width: 10rem;
    min-width: max-content;
    background-color: rgb(229 231 235);
    padding: 0.5rem;
    border-radius: 0.5rem;
    position: absolute;
    z-index: 10;
    transition: ease-in-out 500ms;
}

.filterTitle {
    color: gray;
    font-weight: 600;
    margin: 10px 0;
}

.filterLists {
    margin: 5px 0px;
}

.filterList {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    max-width: max-content;
    gap: 5px;
}

.filterActive {
    color: #38ACEC;
    font-weight: bold;
}