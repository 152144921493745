.channelIndexContainer {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 360px;
    border-right: #e5e7eb solid 2px;
}


.indexHeader{
    display: flex;
    align-items: center;
    gap:5px;
    margin: 10px 0;
    text-decoration: none;
    cursor: pointer;
}
.channelIndexContainerTitle {
    color: #38ACEC;
    font-weight: bold;
    font-size: 20px
}

.chatIndexLists {
    flex-grow: 1;
    padding: 0.5rem;
    overflow: auto;
    padding-bottom: 3rem;

}


@media (max-width: 768px) {
    .channelIndexContainer {
        width:250px;
        overflow: scroll;
    }
}
