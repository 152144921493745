::-webkit-scrollbar {
    /* display: none; */
    width: 4px;
    height: 0px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgb(146, 139, 139);
    border-radius: 6px;
  }
  

  p{
    margin: 5px auto;
  }

  button{
    background: transparent;
    border: none;
  }