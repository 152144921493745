.indexActive {
    background-color: #d3edfa;
}

.index {
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
    margin: 14px 0;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 8px;
    cursor: pointer;
    color: #64748b;
    border-radius: .3rem;
    text-decoration: none;
    overflow: scroll;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

}

.indexDetailsNameMsg {
    width: calc(100% - 50px);
}

.index:hover {
    background-color: #e2f3fb;

}

.indexDetails {
    display: flex;
    align-items: center;
    width: calc(100% - 70px);
}

.indexDetails img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    object-fit: cover;
    object-position: top;
    border-radius: 100%;

}

.indexDetailsName {
    font-size: 1.125rem;
    color: #1f2937;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.indexDetailsMsg {
    font-size: 0.75rem;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.indexDetailsTime {
    font-size: 0.875rem;
    line-height: 1.25rem;
    text-align: end;
    width: 70px;
    text-wrap: nowrap;
}

.indexDetailsCounter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    color: white;
    font-weight: bold;
    font-size:medium;
    
}

.indexDetailsCounter span{
    background-color: #38ACEC;
    min-width: max-content; 
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    font-size: 12px;
}

.hasCounter{
    color: #38ACEC;
}


@media (max-width: 800px) {
    .index {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .index p {
        margin: 2px auto;
    }

    .indexDetailsTime {
        text-align: center;
    }

    .indexDetails {
        flex-direction: column;
    }
}